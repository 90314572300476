const videos = [
    { name: 'Dark Trooper Moff Gideon', desc: `Padme, Raddus & Jabba vs DTMG`, id: '2iLeIG7W86E' },
    { name: 'Taron Malicos', desc: `GAS & SEE vs Cere`, id: 'gIeP7LcHlJg' },
    { name: 'Fury Class Interceptor', desc: `SEE, Padme & GAS vs Malgus`, id: 'mq5JQFfNZ4w' },
    { name: 'Trench', desc: `JKL & Palpatine vs Trench`, id: 'ZD-6SBCmrNk' },
    { name: 'Scythe', desc: `Thrawn & Padme vs GI`, id: 'oz9vvpKJhlU' },
    { name: 'Ben Solo', desc: `SEE & GAS vs Rey`, id: 'GQIfJ9EzH8k' },
    { name: 'Darth Malgus', desc: `SEE vs Malgus`, id: 'OXlaWjZwgYU' },
    { name: 'Tie Interceptor', desc: `SEE & Thrawn vs GI`, id: 'JgI59OjLjVg' },
    { name: 'Boba SOJ', desc: `Padme vs Bounty Hunters`, id: 'Vrdoxu6AEb0' },
    { name: 'Maul', desc: `Padme vs Phoenix`, id: 'u_YSpVHujCU' },
    { name: 'Commander Ahsoka Tano', desc: `Padme vs Maul`, id: 'saH9n0MoTlg' },
];

export {
    videos
}