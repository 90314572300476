import React from 'react';
import classNames from 'classnames';

import Menu from '../menu';
import './_style.scss';

const Page = props => {

  return (
    <div className={classNames('sw-page', props.modifier && `sw-page--${props.modifier}`)}>
      <div className={classNames('sw-page__header', props.modifier && `sw-page__header--${props.modifier}`)}>
        <Menu />
      </div>
      <div className={classNames('sw-page__body', props.modifier && `sw-page__body--${props.modifier}`)}>
        <div className="sw-page__banner">{props.banner && props.banner}</div>
        <div className={classNames('sw-page__container', props.modifier && `sw-page__container--${props.modifier}`)}>
          {props.menu && <div className="sw-page__menu">{props.menu}</div>}
          <div className="sw-page__intro bg-dark">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {props.prepend && props.prepend}
                  <h1>{props.title}</h1>
                  {props.intro}
                  {props.append && props.append}
                  {props.showSocial &&
                    <div className="mt-3 mt-lg-5 row">
                      <div className="col-4 col-xl-auto">
                        <a
                          href="https://discord.gg/PDg8V7gNVW" rel="noopener noreferrer"
                          target="_blank" className="btn btn-discord btn-block"
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="icon-discord" />
                            <span className="ml-2 d-none d-sm-inline">Discord</span>
                          </div>
                        </a>
                      </div>
                      <div className="col-4 px-lg-0 col-xl-auto">
                        <a
                          href="https://www.patreon.com/bitdynasty" rel="noopener noreferrer"
                          target="_blank" className="btn btn-patreon btn-block"
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="icon-patreon" />
                            <span className="ml-2 d-none d-sm-inline">Patreon</span>
                          </div>
                        </a>
                      </div>
                      <div className="col-4 col-md-4 col-xl-auto">
                        <a
                          href="https://www.youtube.com/channel/UC5qi2mtnLwJvd1NCwvrEIOw" rel="noopener noreferrer"
                          target="_blank" className="btn btn-youtube btn-block"
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="icon-youtube" />
                            <span className="ml-2 d-none d-sm-inline">YouTube</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="sw-page__content bg-medium">
            <div className="container">
              {props.children}
              <div className="sw-page__footer text-center pt-5 pb-3">
                <a
                  href="https://www.patreon.com/bitdynasty" rel="noopener noreferrer"
                  target="_blank" className="btn btn-patreon"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="icon-patreon"></span>
                    <span className="ml-2">Support me on Patreon</span>
                  </div>
                </a>
                <div className="font-weight-bold mt-2">Thanks to my Patreons for the support!</div>
                <div>Jimbo6 - Mos Eilith - Bastion Creed - Moldy Eliza - PrenzelDante - MazKanata - TheVFactor - LordQwax - BΛDFΞΞLING - LA One - DarthJrod1633 - xairic - Hosk the Enforcer - Simon S - Blue - Wlm04d - Piett - DarkSideShadoww - Baron Samedi - DAXAR KLAKK - Cass5a - Mol Pokey - pugnation77 - NatanDRB - Randall - ruyen - Jemarti - MAB - Dr. Zin - Hotblack Desiato - winfielddudeman - Sully - MisterGnome - mahler444 - KimNgItsum - undergroundcnc - Ana Foxxx - Quark616 - indysteve - El Mariachi - MadMaxMel - Darth Krul - Amagnus - Masta - Akimmike - CrankyPantz - Keepist - BLM_Noodlenoggin - AvocadoKenobi - HuxPre - Fitter - It Poops Bombs - nole - AngryKoala - Saetras - SixtusHathter - Kio - Stan - scotticidal - Gerry - Speed - Cero - JoeEntropy - SteveT - Blas - lordnex - joojoo - spyhunter - mwdeav - peafour - mugami - ChuckNorrizSaber - DarthSpiff - Soybomb - thx001 - ADM Snackbar - mat walker - amandalorienne - patricke - PinksySnootles - pinkstar - raelphoto - Masta - Mar Trepodi - Ginray - Asterix - Big Mike - MadMaxMel - Tempest144 - Darkimedes - AdamHinton - NoTomorrow - BrokenUtero - Barhis-Sontebren - Jecks - Alfie S 1977 - Endore Ale Co - Darkside VP - Tigra - larryitis
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;